<!-- Remove white space that separates the universal header and regular header.
<p></p>
-->
<div class="background-image-container">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1>Deep Blue Documents</h1>
          <p class="lead">Deep Blue Documents provides access to the work that makes the University of Michigan a leader in research, teaching, and creativity.</p>
          <p class="lead">To find work by U-M authors, browse or search the contents of Deep Blue Documents, then link to what you find using a URL that will always work (available to all). To deposit your work, please <a style="color: white; text-decoration: underline;" href="https://teamdynamix.umich.edu/TDClient/88/Portal/Requests/TicketRequests/NewForm?ID=7Az64AR5TqI_&amp;RequestorType=Service" target="_blank">contact us</a> (available to U-M faculty and staff).</p>
        </div>
      </div>

    </div>
  </div>
  <picture class="background-image">
    <source type="image/jpg" srcset="assets/dspace/images/doc_icons/droplet.jpg 2000w, assets/dspace/images/doc_icons/droplet.jpg 1200w, assets/dspace/images/doc_icons/droplet.jpg 768w">
    <img style="object-position: center;" alt="" [src]="'assets/dspace/images/doc_icons/droplet.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
  <small class="credits">Photo by <a href="https://www.pexels.com/@inspiredimages">@inspiredimages</a></small>
</div>
