<div class="row">
<!-- Don't show thumbnail on search result page.  UM Change.
  <div *ngIf="showThumbnails" class="col-3 col-md-2">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
       [routerLink]="[itemPageRoute]" class="dont-break-out">
      
    <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
    </a>
    <span *ngIf="linkType == linkTypes.None" class="dont-break-out">
    <ds-thumbnail [thumbnail]="dso?.thumbnail | async" [limitWidth]="true">
    </ds-thumbnail>
    </span>
  </div>
-->

<div [ngClass]="showThumbnails ? 'col-9' : 'col-md-12'">
  <!-- This just takes of space.  Not necessary.
  <div class="d-flex">
    <ds-themed-badges *ngIf="showLabel" [object]="dso" [context]="context" [showAccessStatus]="true"></ds-themed-badges>
  </div>
  -->

  <ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
    [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out"
    [innerHTML]="dsoTitle"></a>
    <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out"
    [innerHTML]="dsoTitle"></span>

    <div>
      <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
        (<span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date" [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)
      </ng-container>
    </div>

    <span class="text-muted">
      <ds-truncatable-part [id]="dso.id" [minLines]="1">

        <span *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0" class="item-list-authors">
          <span *ngIf="allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length <= 3">
            <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
              <span [innerHTML]="author"></span>
              <span *ngIf="!last">; </span>
            </span>
          </span>
          <span *ngIf="allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 3">
            <span *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).slice(0,3); let last=last;">
              <span [innerHTML]="author"></span>
              <span *ngIf="!last">; </span>
            </span>
            <span>; et. al.</span>
          </span>
        </span>

      </ds-truncatable-part>
    </span>

    <ds-listfiles [item]="dso"></ds-listfiles>

<!-- No need for abstract in search result page
      <div *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
        <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
          [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
        </ds-truncatable-part>
      </div>
    -->
  </ds-truncatable>
</div>
</div>
