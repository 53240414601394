<footer class="page-footer">
          <div class="viewport-container">
            <div class="page-footer__content">
              <section>
                <h2>University of Michigan Library</h2>
                <ul>
                  <li>
                    <a  href="https://www.lib.umich.edu/">U-M Library</a>
                  </li>
                  <li>
                    <a href="https://publishing.umich.edu/">Michigan Publishing</a>
                  </li>
                  <li>
                    <a href="https://www.lib.umich.edu/about-us/about-library/diversity-equity-inclusion-and-accessibility/accessibility">Accessibility</a>
                  </li>
                </ul>
              </section>

              <section>
                <h2>About Deep Blue Documents</h2>
                <ul>
                    <li>
                        <a href="https://www.lib.umich.edu/collections/deep-blue-repositories">Deep Blue Repositories</a>
                    </li>
                    <li>
                        <a href="https://teamdynamix.umich.edu/TDClient/88/Portal/Requests/TicketRequests/NewForm?ID=7Az64AR5TqI_&RequestorType=Service" target="_blank">Contact Us</a>
                    </li>
                    <li>
                        <a href="assets/static/about/deepbluefaq.html">Frequently Asked Questions</a>
                    </li>
                </ul>
              </section>

              <section>
                <h2>Privacy and copyright</h2>

                <p><a href="https://lib.umich.edu/about-us/policies/library-privacy-statement">Library Privacy Statement</a></p>

                <p>Works found in Deep Blue Documents are protected by copyright unless otherwise indicated.</p>
              </section>
            </div>
          </div>
          <div class="page-footer__disclaimer ">
            <div class="viewport-container">
               <p>©2023 Regents of the University of Michigan. Built with <a href="http://www.dspace.org/">DSpace</a></p>
            </div>
          </div>
  <!-- Copyright -->
</footer>
